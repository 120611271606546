<template>
  <div class="page">
    <PageTitle :title="title" :routes="routes" :border="false"></PageTitle>
    <router-view></router-view>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import PageTitle from '@/components/PageTitle'
export default {
  name: 'Coupon',
  components: {
    PageTitle
  },
  computed: {
    title() {
      let name = '优惠券列表'
      if (this.routes.length > 0) name = this.routes[this.routes.length - 1].name
      return name
    },
    ...mapState({
      routes: 'route'
    })
  },
  mounted() {
    // let path = this.$route.path
    // if (path.indexOf('/') === 0) path = path.substring(1)
    // const currentPage = {
    //   link: path,
    //   name: '优惠券列表'
    // }
    // this.$store.commit('changeRoute', currentPage)
  },
  beforeDestroy() {
    this.$store.commit('changeRoute', null)
  },
  beforeRouteUpdate (to, from, next) {
    let path = to.path
    if (path.indexOf('/') === 0) path = path.substring(1)
    const name = to.query.id ? 'CouponDetail' : to.name
    const chineseName = {
      CouponAdd: '添加优惠券',
      CouponDetail: '编辑优惠券'
    }
    const toPage = {
      link: path,
      name: chineseName[name]
    }
    this.$store.commit('changeRoute', toPage)
    next()
  }
}
</script>

<style scoped lang="scss">
.page {
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
